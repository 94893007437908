import { Component } from '@angular/core';
import {version} from "../../../services/api.service";

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
      <div class="d-flex w-100">
          <p class="flex-grow-1 mb-0"> Created by <b><a href="https://oneteasetech.com" target="_blank">OTT</a></b></p>
          
          <p (click)="onReload()" style="cursor: pointer" class="flex-grow-0 mb-0">v {{versions}}</p>
      </div>
   
  `,
})
export class FooterComponent {
  public versions = version;

  onReload(): void {
    if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.active?.postMessage({ action: 'skipWaiting' });
        }
        window.location.reload();
      });
    }
  }


}
