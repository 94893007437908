<nb-card>
  <nb-card-header>
    Create Support Ticket

    <button nbButton
                  size="tiny"
                  status="basic"
                  (click)="dismiss()"
                  style="float:right">x</button>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
        <div class="col-12">
            <div class="form-group row">
              <label for="affiliate_string_id" class="label col-3 form-control-label">Ticket Type</label>
              <div class="col-9">
                <nb-select [(ngModel)]="ticket.type" fullWidth>
                      <nb-option *ngFor="let i of types" [value]="i.type_id">{{i.name}}</nb-option>
                  </nb-select>
              </div>
            </div>

            <div class="form-group row">
              <label for="affiliate_string_id" class="label col-3 form-control-label">Ticket Status</label>
              <div class="col-9">
                  <nb-select [(ngModel)]="ticket.status" fullWidth>
                      <nb-option *ngFor="let i of basics?.statusses" [value]="i.type_id">{{i.name}}</nb-option>
                  </nb-select>
              </div>
            </div>

            <div class="form-group row">
              <label for="affiliate_string_id" class="label col-3 form-control-label">Ticket Name</label>
              <div class="col-9">
                  <input 
                    type="text" 
                    nbInput 
                    fullWidth 
                    placeholder="Ticket Name"
                    [(ngModel)]="ticket.name">
              </div>
            </div>

            <div class="form-group row">
              <label for="affiliate_string_id" class="label col-3 form-control-label">Description</label>
              <div class="col-9">
                  <textarea
                    nbInput
                    fullWidth
                    [(ngModel)]="ticket.content">
                  </textarea>
              </div>
            </div>

            <div class="form-group row">
              <label for="affiliate_string_id" class="label col-3 form-control-label">Profile_id</label>
              <div class="col-3">
                  <input 
                    type="number" 
                    nbInput 
                    fullWidth 
                    readonly
                    placeholder="101"
                    [(ngModel)]="ticket.profile_id">
              </div>
            </div>
        </div>
    </div>
  </nb-card-body>


  <nb-card-footer>
      <button nbButton status="success" (click)="confirm()" class="dialog-buttons" [disabled]="block_requests">Submit</button>
      <button nbButton status="primary" (click)="dismiss()" class="dialog-buttons" [disabled]="block_requests">Cancel</button>
  </nb-card-footer>
</nb-card>
