import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { NbComponentStatus, NbDialogRef, NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-ticket-dialog',
  templateUrl: 'ticket-dialog.component.html',
  styleUrls: ['ticket-dialog.component.scss'],
})
export class ticketDialogComponent implements OnInit {
  @Input() import_ticket?: any = 0;
  @Input() report?: boolean;
  @Input() name?: string;
  @Input() id?: number;
  @Input() profile_id?: number;

  public ticket?: any;
  public basics;
  public types: Array<any> = [];
  public block_requests: boolean = false;

  constructor(
    protected ref: NbDialogRef<ticketDialogComponent>,
    private apiService: ApiService,
    private toastrService: NbToastrService
  ) { }

  ngOnInit() {
    this.getBasics();
    this.setDefaultTicket();
  }

  private getBasics(): void {
    this.apiService.getTicketsInfo().subscribe(
      (response: any) => {
        this.basics = response.data;
        this.types = this.basics?.types;
        if (this.report) this.types = this.basics?.types_all?.filter(t => t.type_id === 1 || t.type_id === 3);
      },
      (error) => {
        this.showToast('Error', error.error.detail, 'danger');
      }
    )
  }

  setDefaultTicket(): void {
    if (this.import_ticket) {
      this.ticket = this.import_ticket;
    } else {
      let type = this.report ? 1 : 2;
      let profile_id = this.profile_id || 0;

      this.ticket = {
        type: type,
        status: 1,
        name: '',
        content: '',
        profile_id: profile_id,
      }
    }
  }

  dismiss() {
    this.ref.close();
  }

  confirm() {
    this.block_requests = true;

    let ticket = {...this.ticket}

    if(this.report) {
      let id = this.id ? `(${this.id})` : ''
      ticket.name = this.name + id + ' - '  +  this.ticket.name;
    }

    this.apiService.createTicket(ticket).subscribe(
      (response: any) => {
        this.showToast('Support Ticket', 'submitted');
        this.ref.close(response.data)
      },
      (error) => {
        this.showToast('Error', error.error.detail, 'danger');
        this.block_requests = false;
      }
    )
  }

  showToast(title: string, message: string, status: NbComponentStatus = 'success') {
    this.toastrService.show(title, message, { status });
  }
}
