import {Component, ElementRef, OnInit} from '@angular/core';
import { MainUserIdService } from '../../../services/mainUserId.service';
import { ChattingService } from '../../../services/chatting.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed [style.background-color]="bgColor"
        [ngStyle]="{'display': ((this.headerVisibility$ | async) === true) ? 'block' : 'none'}">
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" [style.background-color]="bgColor" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column style="padding: 15px 15px 0">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnInit {
  bgColor: string = '#fff';
  defaultActiveColor = '#9800B6';
  defaultCNLActiveColor = '#FFFF00';
  defaultTestActiveColor = '#4169E1';

  public headerVisibility$ = this.chattingService.headerVisibility$;
  constructor(
    private mainUserIdService: MainUserIdService,
    private elementRef: ElementRef,
    private chattingService:ChattingService,
  ) {
     this.elementRef.nativeElement.style.setProperty('--active_color','#49526A');
     this.elementRef.nativeElement.style.setProperty('--sidebar_ico','#49526A');

  }

   public ngOnInit(): void {
    this.mainUserIdService.color_profile$.subscribe(color => {
        this.bgColor = color['bg-color'];
       this.elementRef.nativeElement.style.setProperty('--title_color',color['font-color']);
       this.elementRef.nativeElement.style.setProperty('--sidebar_ico',color['font-color']);
       this.elementRef.nativeElement.style.setProperty('--logo-1_color',color['font-color']);

      if (this.bgColor == '#FAE6FA') {//bg-pink
          this.elementRef.nativeElement.style.setProperty('--active_color',this.defaultActiveColor);
          this.elementRef.nativeElement.style.setProperty('--sidebar_line', "#EAE7F2");

      } else if (this.bgColor == '#524e63') { //bg-dark
          this.elementRef.nativeElement.style.setProperty('--active_color',this.defaultCNLActiveColor);
          this.elementRef.nativeElement.style.setProperty('--sidebar_line', "#CDCAD9");

      } else if (this.bgColor == '#DFE8FF') { //testing
          this.elementRef.nativeElement.style.setProperty('--active_color',this.defaultTestActiveColor);
          this.elementRef.nativeElement.style.setProperty('--sidebar_line', "#9FB7FF");
      }
    });

    }

}
