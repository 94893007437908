import { Component, Input, OnInit } from '@angular/core';
import { interval, Observable, of } from "rxjs";
import { map, startWith, takeWhile } from "rxjs/operators";

@Component({
  selector: 'ngx-timer',
  styleUrls: ['../../../../pages/new-chatting/chatting-content/chatting-content.component.scss'],
  template: `
    <div class="text-center">
      <span style="white-space: nowrap;">
        {{ countDown | async }}
      </span>
    </div>
  `,
})

export class CellTimerComponent implements OnInit {
  @Input() value: number;
  public countDown: Observable<string>;

  public ngOnInit() {
    const maxVisibleTime = 5940000;
    let milliseconds = Math.floor(this.value);

    if(maxVisibleTime > milliseconds) {
      this.countDown = interval(60000).pipe(
        takeWhile(() => maxVisibleTime > milliseconds),
        startWith(() => this.calcMinutes(milliseconds)),
        map(() => {
          milliseconds += 60000;
          return this.calcMinutes(milliseconds);
        }),
      );
    }
    else {
      this.countDown = of("99 minutes ago")
    }
  }

  private calcMinutes(milliseconds: number): string {
    return `${
      Math.floor((milliseconds / 1000) / 60)
    } minutes ago`;
  }
}



