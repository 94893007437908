import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-styled-cell',
  template: `
    <span [ngClass]=" +value === 0 ? '' 
                    : (+value <= min) ? 'red' 
                    : (+value >= max_reverse) ? 'red' 
                    : (+value >= max) ? 'green' 
                    : 'orange' " 
    >{{renderValue}}</span>
  `,
  styles: [
  '.green { color: green }',
  '.orange { color: orange }',
  '.red { color: red }'
],
})
export class StyledCellComponent implements OnInit {
  @Input() rowData?: any;
  @Input() value: any;
  @Input() min?: number;
  @Input() max?: number;
  @Input() max_reverse?: number;
  @Input() prefix?: string = '';
  @Input() postfix?: string = '';
  @Input() showZero?: boolean = false;

  renderValue: string;

  constructor() { }

  ngOnInit(): void {
    this.renderValue = `${this.prefix} ${this.value} ${this.postfix}`;
    if (this.value === 0 && !this.showZero) this.renderValue = '-';
  }

}
