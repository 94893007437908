import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class RequestStatusService {

  public errorMessage = new BehaviorSubject<string>('');
  public errorMessage$ = this.errorMessage.asObservable();

  constructor() {}

  setErrorMessage(mes: string): void {
   this.errorMessage.next(mes);
  }

  cleanErrorMessage(): void {
   this.errorMessage.next('');
  }

}
