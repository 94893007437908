import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import {Observable, Subject, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MainUserIdService } from "../mainUserId.service";


@Injectable()
export class AppIdHttpInterceptor implements HttpInterceptor {

  constructor(private mainUserIdService: MainUserIdService) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any> | never> {

   const id: number = this.mainUserIdService.getClientId();
    this.mainUserIdService.client_id$.subscribe();

    let copy_request:any
    if (id > 0) {
      copy_request = request.clone({
        setHeaders: {
          Ottid: id.toString()
        }
      });
    } else {
      copy_request = request.clone();
    }

    return next.handle(copy_request).pipe(
      catchError((error) => {
        return this.errorHandler(request, error);
      }));
  }

  errorHandler(request: HttpRequest<any>, error: any): Observable<never> {
    if (error instanceof HttpErrorResponse
      ) {

    }
    return throwError(() => error);
  }

}