import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {ApiService} from "./api.service";

@Injectable({
    providedIn: 'root'
})
export class CurrentUserDataService {
    private currentUserData = new BehaviorSubject<any | null>(null);
    private statusUpdatedFromComp = new BehaviorSubject<boolean>(false);

    public init = false;

    constructor(private apiService: ApiService) {
    }

    public fetchUserProfile(): void {
        this.apiService.getMyProfile().subscribe(
            (response: any) => {
                this.setCurrentUserData(response.data);
            }
        )
    }


    triggerProfileUpdate(): void {
        this.fetchUserProfile();
        this.statusUpdatedFromComp.next(true);
    }

     public setCurrentUserData(value: any): any | null {
        this.currentUserData.next(value);
    }


    getCurrentUserData(): Observable<any> {
        return this.currentUserData.asObservable();
    }

}