import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {ApiService} from "./api.service";

@Injectable({
    providedIn: 'root'
})
export class ChattingService {

    public headerVisibility$:BehaviorSubject<boolean> = new BehaviorSubject(true);
    public pendingChatsCount$:BehaviorSubject<number | string> = new BehaviorSubject(0)
    public init = false;

    constructor(private apiService: ApiService) {
    }

}