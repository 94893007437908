import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
  styleUrls: ['./style.component.scss'],
  template: `
    <span style="cursor: pointer;text-decoration: underline;" (click)="changeEvent()">{{value}}</span>
  `,
})
export class linkRenderComponent implements ViewCell {

  renderValue: string | number;

  @Input() value: any;
  @Input() rowData: any;
  @Output() edit: EventEmitter<any> = new EventEmitter();

  constructor() { }

  changeEvent() {
      let data = {
        id: this.rowData.id,
        rowData: this.rowData,
      };

      this.edit.emit(data);
  }
}