import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NbAuthService} from '@nebular/auth';
import {BehaviorSubject, interval, Subscription} from 'rxjs';
import {MainUserIdService} from "./mainUserId.service";
import {takeWhile} from "rxjs/operators";

@Injectable({providedIn: 'root'})

export class TokenService {
    public role = new BehaviorSubject<number | null>(null);
    public tokenData = JSON.parse(localStorage.getItem('ottTokenData') || '{}');
    public checkTokenSubscription: Subscription;


    constructor(
        private nbService: NbAuthService,
        private router: Router,
        private mainUserIdService: MainUserIdService,
    ) {
    }

    public getTokenInfo(): void {
        this.nbService.onTokenChange().subscribe(token => {
            this.nbService.isAuthenticated().subscribe(auth => {
                if (!auth && !token['token']) return;
                else if (!auth && !token.isValid()) this.onLogout();
                else if (!token || !token.isValid()) this.onLogout();
                else {
                    let role = token.getPayload()['role'];
                    this.role.next(role);
                    this.checkTokenRefresh(token['createdAt']);
                }
            })
        });
    };

    public checkTokenExpiration(createdTimestamp?: number) {
        const now = new Date().getTime();
        const createdAt = new Date(createdTimestamp).getTime();
        const expiresAt = createdAt + (180 * 60 * 1000);

        const expiresIn = expiresAt - now;
        const expired = expiresIn < 0;
        const refreshIn = expiresIn - (10 * 60 * 1000);

        if (expired) this.onLogout();
        return refreshIn < 0
    }

    checkTokenRefresh(createdTimestamp) {
        let isExpired = this.checkTokenExpiration(createdTimestamp);

        this.checkTokenSubscription = interval(60000)
            .pipe(takeWhile(() => !isExpired))
            .subscribe(() => {
                isExpired = this.checkTokenExpiration(createdTimestamp);
                if (isExpired) this.refreshToken();
            });
    }

    clearRefreshTokenSub(): void {
        if (this.checkTokenSubscription) {
            this.checkTokenSubscription.unsubscribe();
        }
    }

    private refreshToken(): void {
        this.nbService.isAuthenticated().subscribe(
            (auth) => {
                if (auth) {
                    this.nbService.refreshToken('username').subscribe(
                        (result: any) => {
                            let now = new Date().getTime();
                            this.tokenData.expires_timestamp = now + result.response.body.expires_minutes * 60 * 1000;
                            localStorage.setItem('ottTokenData', JSON.stringify(this.tokenData));
                            this.checkTokenRefresh(now);
                        },
                        () => this.onLogout())
                } else this.onLogout();
            },
            () => this.onLogout());
    }

    public checkAuthTokenExpiration() {
         this.nbService.isAuthenticated().subscribe((result) => {
             if (!result) { this.onLogout(); }
        });
    }

    public onLogout(): void {
        this.role.next(null);
        localStorage.removeItem('auth_app_token');
        this.mainUserIdService.setClientId(0, false);
        localStorage.removeItem('ottTokenData');
        this.clearRefreshTokenSub();
        this.router.navigateByUrl('/auth/logout');
    }
}
