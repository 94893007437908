import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TokenService } from '../token.service';
import { NbComponentStatus, NbToastrService } from "@nebular/theme";
import { RequestStatusService } from "../request-status.service";

@Injectable()
export class CredsErrorInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: TokenService,
    private toastService: NbToastrService,
    private requestStatusService: RequestStatusService
  ) {}


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        return this.errorHandler(request, error);
      }));
  }

  errorHandler(request: HttpRequest<any>, error: any): Observable<never> {
      const err = error();
       if (request.url.includes('chat/tickets/full')
           || request.url.includes('back/chats/tickets/reply')
           || request.url.includes('back/chat/ticket')
           || request.url.includes('back/chat/ticket/onhold')
           || request.url.includes('back/content/profile/message/update')) {
           this.requestStatusService.setErrorMessage(err.error.detail);
       }

    if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
           this.showToast('Error', err.error.detail, 'danger');
            this.tokenService.onLogout();
        } else {
         this.showToast('Error', err.error.detail, 'danger');
        }
    }

    return throwError(() => error);
  }

   public showToast(title: string, message: string, status: NbComponentStatus = 'danger'): void {
    this.toastService.show(title, message, {status});
  }

}
